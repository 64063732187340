require('is-in-viewport/lib/isInViewport.js');
/********************************      transportGeography -- map settings      *******************/
function transportGeographyMapSettings() {
  var myMap,
    mapIsLoaded = false,
    $window = $(window);
    var oId = 0;
    var data = {
      "type": "FeatureCollection",
      "features": [
        {
          "type": "Feature",
          "id": oId++,
          "geometry": {
            "type": "Point",
            "coordinates": [53.411838, 91.064107]
          },
          "properties": {
            "img": "arshanovo.jpg",
            "caption": "Аршанов"
          }
        }, {
          "type": "Feature",
          "id": oId++,
          "geometry": {
            "type": "Point",
            "coordinates": [50.595660, 36.587223]
          },
          "properties": {
            "img": "belgorod.jpg",
            "caption": "Белгород"
          }
        }, {
          "type": "Feature",
          "id": oId++,
          "geometry": {
            "type": "Point",
            "coordinates": [58.603581, 49.667978]
          },
          "properties": {
            "img": "kirov.jpg",
            "caption": "Киров"
          }
        }, {
          "type": "Feature",
          "id": oId++,
          "geometry": {
            "type": "Point",
            "coordinates": [55.753215, 37.622504]
          },
          "properties": {
            "img": "moscow.jpg",
            "caption": "Москва"
          }
        }, {
          "type": "Feature",
          "id": oId++,
          "geometry": {
            "type": "Point",
            "coordinates": [58.201698, 68.253762]
          },
          "properties": {
            "img": "vladivostok-tobolsk.jpg",
            "caption": "Владивосток-Тобольск"
          }
        }, {
          "type": "Feature",
          "id": oId++,
          "geometry": {
            "type": "Point",
            "coordinates": [60.710232, 28.749404]
          },
          "properties": {
            "img": "vyborg.jpg",
            "caption": "Выборг"
          }
        }, {
          "type": "Feature",
          "id": oId++,
          "geometry": {
            "type": "Point",
            "coordinates": [60.625264, 28.568016]
          },
          "properties": {
            "img": "vysock.jpg",
            "caption": "Высоцк"
          }
        }, {
          "type": "Feature",
          "id": oId++,
          "geometry": {
            "type": "Point",
            "coordinates": [62.618489, 29.555669]
          },
          "properties": {
            "img": "ylamylly.jpg",
            "caption": "Ylämylly (Илюмюллю, Финляндия)"
          }
        }, {
          "type": "Feature",
          "id": oId++,
          "geometry": {
            "type": "Point",
            "coordinates": [44.166267, 45.872398]
          },
          "properties": {
            "img": "dagestan-terekli.jpg",
            "caption": "Дагестан, Терекли"
          }
        }, {
          "type": "Feature",
          "id": oId++,
          "geometry": {
            "type": "Point",
            "coordinates": [52.033973, 113.499432]
          },
          "properties": {
            "img": "chita.jpg",
            "caption": "Чита"
          }
        }, {
          "type": "Feature",
          "id": oId++,
          "geometry": {
            "type": "Point",
            "coordinates": [62.238116, 25.746677]
          },
          "properties": {
            "img": "iuveaskiulea.jpg",
            "caption": "Финляндия, Ювяскюля"
          }
        }, {
          "type": "Feature",
          "id": oId++,
          "geometry": {
            "type": "Point",
            "coordinates": [58.048454, 38.858406]
          },
          "properties": {
            "img": "rybinsk.jpg",
            "caption": "Рыбинск"
          }
        }, {
          "type": "Feature",
          "id": oId++,
          "geometry": {
            "type": "Point",
            "coordinates": [61.668831, 50.836461]
          },
          "properties": {
            "img": "siktivkar.jpg",
            "caption": "Сыктывкар"
          }
        }, {
          "type": "Feature",
          "id": oId++,
          "geometry": {
            "type": "Point",
            "coordinates": [59.568556, 17.528566]
          },
          "properties": {
            "img": "suedia-balsta.jpg",
            "caption": "Швеция, Больста"
          }
        },
        {
          "type": "Feature",
          "id": oId++,
          "geometry": {
            "type": "Point",
            "coordinates": [64.499550, 14.142896]
          },
          "properties": {
            "img": "suedia-gadede.jpg",
            "caption": "Швеция, Еддеде"
          }
        },
        {
          "type": "Feature",
          "id": oId++,
          "geometry": {
            "type": "Point",
            "coordinates": [60.753825, 37.295050]
          },
          "properties": {
            "img": "annenskoe-vytegorskiy-r-n.jpg",
            "caption": "Анненское Вытегорский р-н"
          }
        },
        {
          "type": "Feature",
          "id": oId++,
          "geometry": {
            "type": "Point",
            "coordinates": [56.151362, 101.634080]
          },
          "properties": {
            "img": "bratsk.jpg",
            "caption": "Братск"
          }
        },
        {
          "type": "Feature",
          "id": oId++,
          "geometry": {
            "type": "Point",
            "coordinates": [55.796289, 49.108795]
          },
          "properties": {
            "img": "kazan.jpg",
            "caption": "Казань"
          }
        },
        {
          "type": "Feature",
          "id": oId++,
          "geometry": {
            "type": "Point",
            "coordinates": [55.888740, 37.430390]
          },
          "properties": {
            "img": "himki-mo.jpg",
            "caption": "Химки, МО"
          }
        },
        {
          "type": "Feature",
          "id": oId++,
          "geometry": {
            "type": "Point",
            "coordinates": [59.122612, 37.903461]
          },
          "properties": {
            "img": "cherepovets.jpg",
            "caption": "Череповец"
          }
        },
      ]
    };


  var $transportGeographyCore = $('.transport-geography__map');

  var $mapPreloader = $transportGeographyCore.find('.transport-geography__preloader'),
    $mapPreloaderOverlay = $transportGeographyCore.find('.transport-geography__preloader-overlay');

  // check if map is in viewport and run some actions (once and on scroll)
  // $window.on('scroll', _.debounce(whenMapIsInViewportActions, 200)).trigger('scroll');
    whenMapIsInViewportActions();
  /**
     * Some actions for map in a viewport
     */
  function whenMapIsInViewportActions() {
    // if map loading no need anymore
    if (
      $transportGeographyCore.hasClass('map-loading') || $transportGeographyCore.hasClass('transport-geography__map--expanded')) {
        return;
    }
    // if ($transportGeographyCore.is(':in-viewport')) {
    //   expandMap();
    // }
    expandMap();


    // // if that is touch device
    // if (Modernizr.touchevents) {
    //
    // }
    // run some actions after core block will be visible (on no touch devices)
    // if ($transportGeographyCore.hasClass('aos-animate')) {
    //   expandMap();
    // } else {
    //
    // }
  }

  /**
     * Expand map instructions
     */
  function expandMap() {

    if (mapIsLoaded) {
      return;
    }

    $transportGeographyCore.addClass('transport-geography__map--expanded');

    !mapIsLoaded && ($mapPreloaderOverlay.fadeIn(), $mapPreloader.fadeIn());

    // if ymaps is not defined - get it
    if (typeof ymaps === 'undefined') {
      var lang = 'en_US';
        if(CDict.locale === 'ru') {
          lang = 'ru_RU';
        }

        $.getScript('//api-maps.yandex.ru/2.1/?lang=' + lang, afterYMapsReady);
    } else {
      afterYMapsReady();
    }

    function afterYMapsReady() {
      $transportGeographyCore.addClass('map-loading');
      ymaps.ready(YandexReadyHandlerSiteMap);
    }

  }

  /**
     * Yandex map ready callback
     */
  function YandexReadyHandlerSiteMap() {
    if (typeof ymaps === 'undefined') {
      setTimeout(YandexReadyHandlerSiteMap, 500);
      return;
    }
    $mapPreloader.fadeOut(),
    $mapPreloaderOverlay.fadeOut(),
    mapIsLoaded = true;
    $transportGeographyCore.removeClass('map-loading');

    if (!myMap) {
      myMap = new ymaps.Map("map", {
        center: [
          60.603707, 96.629345
        ],
        zoom: 4,
        controls: [],
        type: "yandex#map"
      }, {
        suppressMapOpenBlock: true,
        minZoom: 2,
      });

      var zoomControl = new ymaps.control.ZoomControl({
        options: {
          size: "auto",
          adjustMapMargin: true,
          position: {
            right: 10,
            top: 220
          }
        }
      });

      myMap.controls.add(zoomControl);

      /*
       * Макет кнопки должен отображать поле data.content
       * и изменяться в зависимости от того, нажата ли кнопка или нет.
       * Текущий размер (small, medium, large) рассчитывается исходя из значения опции maxWidth
       * @see https://api.yandex.ru/maps/doc/jsapi/2.1/ref/reference/control.Button.xml#param-parameters
       */
      var ButtonLayout = ymaps.templateLayoutFactory.createClass([
              '<div class="toggle-touch ',
              '{% if state.size == "small" %}toggle-touch--small{% endif %}',
              '{% if state.size == "medium" %}toggle-touch--medium{% endif %}',
              '{% if state.size == "large" %}toggle-touch--large{% endif %}',
              '{% if state.selected %} toggle-touch--selected{% endif %}">',
              '<img class="toggle-touch__img toggle-touch__img--touch" src="{{ data.imageSelected }}">',
              '<img class="toggle-touch__img toggle-touch__img--no-touch" src="{{ data.image }}">',
              '</div>'
          ].join('')),

      button = new ymaps.control.Button({
          data: {
              image: '/assets/images/icons/no-touch.svg',
              imageSelected: '/assets/images/icons/touch.svg',
          },
          options: {
              layout: ButtonLayout,
          }
      });

      button.events
        .add(
          'select',
          function () {
            myMap.behaviors.enable('drag');
          }
        )
        .add(
          'deselect',
          function () {
            myMap.behaviors.disable('drag');
          }
        );



      myMap.controls.add(button, {
          right: 5,
          top: 5
      });
      // Создание макета балуна на основе Twitter Bootstrap.
      // var MyBalloonLayout = ymaps.templateLayoutFactory.createClass(
      //      '<div class="tooltipster-base tooltipster-sidetip tooltipster-top c-tooltip_map">' +
      //          '<div class="tooltipster-box">' +
      //              '<span class="tooltipster-close close c-btn_close с-btn_white font-icon icon-delete">x</span>' +
      //              '<div class="tooltipster-content"><div class="o-info-window">' +
      //                  '$[[options.contentLayout observeSize minWidth=235 maxWidth=235 maxHeight=350]]' +
      //              '</div></div>' +
      //          '</div>' +
      //          '<div class="tooltipster-arrow-uncropped"><div class="tooltipster-arrow-border"></div><div class="tooltipster-arrow-background"></div></div>' +
      //     '</div>', {
      //     /**
      //      * Строит экземпляр макета на основе шаблона и добавляет его в родительский HTML-элемент.
      //      * @see https://api.yandex.ru/maps/doc/jsapi/2.1/ref/reference/layout.templateBased.Base.xml#build
      //      * @function
      //      * @name build
      //      */
      //     build: function () {
      //         this.constructor.superclass.build.call(this);
      //
      //         this._$element = $('.c-tooltip_map', this.getParentElement());
      //
      //         this.applyElementOffset();
      //
      //         this._$element.find('.close')
      //             .on('click', $.proxy(this.onCloseClick, this));
      //     },
      //
      //     /**
      //      * Удаляет содержимое макета из DOM.
      //      * @see https://api.yandex.ru/maps/doc/jsapi/2.1/ref/reference/layout.templateBased.Base.xml#clear
      //      * @function
      //      * @name clear
      //      */
      //     clear: function () {
      //         this._$element.find('.close')
      //             .off('click');
      //
      //         this.constructor.superclass.clear.call(this);
      //     },
      //
      //     /**
      //      * Метод будет вызван системой шаблонов АПИ при изменении размеров вложенного макета.
      //      * @see https://api.yandex.ru/maps/doc/jsapi/2.1/ref/reference/IBalloonLayout.xml#event-userclose
      //      * @function
      //      * @name onSublayoutSizeChange
      //      */
      //     onSublayoutSizeChange: function () {
      //         MyBalloonLayout.superclass.onSublayoutSizeChange.apply(this, arguments);
      //
      //         if(!this._isElement(this._$element)) {
      //             return;
      //         }
      //
      //         this.applyElementOffset();
      //
      //         this.events.fire('shapechange');
      //     },
      //
      //     /**
      //      * Сдвигаем балун, чтобы "хвостик" указывал на точку привязки.
      //      * @see https://api.yandex.ru/maps/doc/jsapi/2.1/ref/reference/IBalloonLayout.xml#event-userclose
      //      * @function
      //      * @name applyElementOffset
      //      */
      //     applyElementOffset: function () {
      //         this._$element.css({
      //             left: -(this._$element[0].offsetWidth / 2),
      //             top: -(this._$element[0].offsetHeight + this._$element.find('.arrow')[0].offsetHeight)
      //         });
      //     },
      //
      //     /**
      //      * Закрывает балун при клике на крестик, кидая событие "userclose" на макете.
      //      * @see https://api.yandex.ru/maps/doc/jsapi/2.1/ref/reference/IBalloonLayout.xml#event-userclose
      //      * @function
      //      * @name onCloseClick
      //      */
      //     onCloseClick: function (e) {
      //         e.preventDefault();
      //
      //         this.events.fire('userclose');
      //     },
      //
      //     /**
      //      * Используется для автопозиционирования (balloonAutoPan).
      //      * @see https://api.yandex.ru/maps/doc/jsapi/2.1/ref/reference/ILayout.xml#getClientBounds
      //      * @function
      //      * @name getClientBounds
      //      * @returns {Number[][]} Координаты левого верхнего и правого нижнего углов шаблона относительно точки привязки.
      //      */
      //     getShape: function () {
      //         if(!this._isElement(this._$element)) {
      //             return MyBalloonLayout.superclass.getShape.call(this);
      //         }
      //
      //         var position = this._$element.position();
      //
      //         return new ymaps.shape.Rectangle(new ymaps.geometry.pixel.Rectangle([
      //             [position.left, position.top], [
      //                 position.left + this._$element[0].offsetWidth,
      //                 position.top + this._$element[0].offsetHeight + this._$element.find('.arrow')[0].offsetHeight
      //             ]
      //         ]));
      //     },
      //
      //     /**
      //      * Проверяем наличие элемента (в ИЕ и Опере его еще может не быть).
      //      * @function
      //      * @private
      //      * @name _isElement
      //      * @param {jQuery} [element] Элемент.
      //      * @returns {Boolean} Флаг наличия.
      //      */
      //     _isElement: function (element) {
      //         return element && element[0] && element.find('.arrow')[0];
      //     }
      //   });
      // Создание вложенного макета содержимого балуна.
      var MyBalloonContentLayout = ymaps.templateLayoutFactory.createClass(
          '<figure class="img-responsive img-responsive--with-link img-responsive--with-overlay"><a href="/assets/images/map/$[properties.img]" class="img-responsive__link " data-fancybox data-type="image" data-caption="$[properties.caption]"><img src="/assets/images/map/thumbs/$[properties.img]" alt="$[properties.caption]" class="img-responsive__item"></a></figure>'
       );


      var objectManager = new ymaps.ObjectManager({
        // Чтобы метки начали кластеризоваться, выставляем опцию.
        clusterize: true,
        // ObjectManager принимает те же опции, что и кластеризатор.
        gridSize: 32,
        // clusterDisableClickZoom: false,
        // geoObjectOpenBalloonOnClick: false
      });

      // Чтобы задать опции одиночным объектам и кластерам,
      // обратимся к дочерним коллекциям ObjectManager.

      objectManager.objects.options.set('preset', 'islands#blueDotIcon');
      objectManager.objects.options.set('balloonShadow', false);
      objectManager.objects.options.set('balloonContentLayout', MyBalloonContentLayout);
      objectManager.objects.options.set('balloonPanelMaxMapArea', 0);
      objectManager.objects.options.set('hideIconOnBalloonOpen', false);
      objectManager.objects.options.set('balloonOffset', [3, -40]);
      // objectManager.clusters.options.set('preset', 'islands#redClusterIcons');

      // objectManager.objects.events.add('click', function(e) {
      //   var obj = e.get('objectId');
      //   objectManager.objects.balloon.open(obj);
      //
      // });

      // objectManager.objects.balloon.events.add('mouseleave', function() {
      //   objectManager.objects.balloon.close();
      // })

      myMap.geoObjects.add(objectManager);

      objectManager.add(data);

      if (Modernizr.touchevents) {
        //  myMap.events.add('touchstart', function (e) {
        //     e.preventDefault(); // При двойном щелчке зума не будет.
        //     alert('свайпить незя')
        //  });
        myMap.behaviors.disable('drag');
      }

      myMap.container.fitToViewport();
      myMap.behaviors.disable('scrollZoom');
      myMap.behaviors.disable('wheel');
      // Масштабируем карту на область видимости геообъекта.
      myMap.setBounds(objectManager.getBounds(), {
          checkZoomRange: true // проверяем наличие тайлов на данном масштабе.
      });
      return myMap;
    } else {
      myMap.destroy(); // Деструктор карты
      myMap = null;
    }
  }
}

domready(function() {
  exports.init = transportGeographyMapSettings
})
