var Instafeed = require("instafeed.js");
var jQueryBridget = require('jquery-bridget');
var Masonry = require('masonry-layout');
// make Masonry a jQuery plugin
jQueryBridget( 'masonry', Masonry, $ );
// now you can use $().masonry()

require('imagesloaded');

domready(function () {
  exports.init = function () {
    // var $portfolio__moreExamplesContainer = $('.portfolio__more-examples');
    // var portfolio__isExpanded = false;
    // var $portfolio__loadMoreButton = $('.portfolio__load-more-button');
    var template = `
      <div class="portfolio__example">
          <figure class="img-responsive img-responsive--with-link img-responsive--with-overlay">
            <a href="--image--" class="img-responsive__link " data-fancybox="gallery" data-type="image" data-caption="--caption--">
              <img src="--image_sm--" alt="--alt--" class="img-responsive__item">
            </a>
          </figure>
      </div>`;
    var $grid = $('#portfolio_gallery');
    var maxCount = $grid.data('max-count') || 20;


    $.ajax({
      url: 'https://nst-spb.ru/admin/get_pictures.php',
      type: 'GET',
      dataType: 'json',
    })
      .done(function (data) {
        var items = [];
				data.slice(0, parseInt(maxCount, 10)).map(function(item, i){
          var itemStr = template
            .replace('--image--', 'https://nst-spb.ru' + item.image)
            .replaceAll('--image_sm--', 'https://nst-spb.ru' + item.image_sm)
            .replaceAll('--caption--', item.caption)
            .replaceAll('--alt--', item.caption);
					items.push(itemStr);
				})
				$grid.html(items.join(''));
        $grid.masonry({
          // options...
          itemSelector: '.portfolio__example',
          // columnWidth: 200,
          percentPosition: true,
          // columnWidth: 160,
          transitionDuration: 0
        });

        $grid.imagesLoaded().progress(function () {
          $grid.masonry('layout');
        });
    })
    .fail(function(request, textStatus, errorThrown) {});
  }
});
