domready(function () {
    exports.init = function () {
        

        function getTitle($button) {
            return $button.parent().find('.trawl__title').text();
        }

        

        $(document).on('afterShow.fb', function( e, instance, slide) {
            // Clicked element
            const $btn = $.fancybox.getInstance().current.opts.$orig;
            console.log( 'Clicked element', $btn );
            if(slide.src === '#action-order') {
                const title = getTitle($btn);
                $('#trawl').val(title);
            }
        });
        $(document).on('beforeClose.fb', function( e, instance, slide) {
            // alert(slide.src);
            // if doesn't gallery
            if(slide.src === '#action-order') {
                $('#trawl').val('');
            }
        });
    }
})