import universalLanguageDetect from '@unly/universal-language-detector';

const SUPPORTED_LANGUAGES = ['en', 'ru', 'de', 'zh'];
const FALLBACK_LANG = 'en';

const lang = universalLanguageDetect({
  supportedLanguages: SUPPORTED_LANGUAGES, // Whitelist of supported languages, will be used to filter out languages that aren't supported
  fallbackLanguage: FALLBACK_LANG, // Fallback language in case the user's language cannot be resolved
});

const languageDetector = {
  supportedLanguages: SUPPORTED_LANGUAGES,
  fallbackLanguage: FALLBACK_LANG,
  lang,
};

window.languageDetector = languageDetector;
