domready(function () {
    exports.init = function () {

        var Accordion = function (el, options) {
            const parent = $(el);
            const titles = parent.find('> .accordion__title')
            const contents = parent.find('> .accordion__content');
            const collapse = parent.is('[data-collapse]')
            const speed = 300
            let activeIndex = parent.find('> .show').index() || 0

            titles.on('click', function () {
                let elm = $(this)
                toggle(elm)
            })

            function toggle(clickedElm) {
                let content = clickedElm.next()
                content.slideToggle(speed)
                activeIndex = clickedElm.index()
                clickedElm.toggleClass('show')

                if ( !collapse ) return
                collapseOthers()
            }

            function collapseOthers() {
                titles.each(function (i, title) {
                    let content = $(title).next()

                    if ( $(title).index() != activeIndex ){
                        content.slideUp(speed)
                        $(title).removeClass('show')
                    }
                })
            }
        }

        var accordions = document.querySelectorAll('.accordion')
        for (var i = 0; i < accordions.length; i++) {
            new Accordion(accordions[i])
        }

    }
});