import tippy from 'tippy.js';

$(function(){
  // btnFilter.addEventListener(
  //   "click",
  //   function(ev) {
  //     alert("btnFilter was clicked!");
  //     console.log("btnFilter was clicked!");
  //     // filter stuff goes here
  //   },
  //   false
  // );

  const $triggers = $('.js-language-select-trigger');
  if(!$triggers.length > 0) return;
  $triggers

  tippy(
    '.js-language-select-trigger',
    {
      theme: "light",
      distance: 0,
      maxWidth: 260,
      interactive: true,
      arrow: false,
      trigger: "click",
      placement: $(window).width() >= 768 ? "bottom-end" : "bottom",
      // content: document.getElementById('')
      content(reference) {
        const id = reference.getAttribute('data-template');
        const template = document.getElementById(id);
        return template.innerHTML;
      }
    }
  );
})
